<script setup lang="ts">
import { storeToRefs } from 'pinia';
import MainLayout from '~/components/layout/MainLayout.vue';
import AppSidebar from '~/components/navigation/appSidebar/AppSidebar.vue';
import { useMainStore } from '~/stores/mainStore';
import UnsupportedDevice from '~/components/emptyStates/UnsupportedDevice.vue';

const store = useMainStore();
const { page } = storeToRefs(store);
const { defaultClasses } = useLayout();
</script>

<template>
  <MainLayout>
    <template #nav>
      <AppSidebar />
    </template>
    <template #page>
      <UnsupportedDevice />

      <main
        ref="page"
        class="w-full flex-1 overflow-auto"
        :class="defaultClasses"
        data-cy="default-main"
      >
        <div class="mx-auto max-w-screen-2xl p-4 md:p-8">
          <slot></slot>
        </div>
      </main>
    </template>
  </MainLayout>
</template>
